import React from 'react'
import { graphql } from 'gatsby'
import find from 'lodash/find'
import { Flex, Image, Text, Stack, Box, Heading, Button } from '@pw/ui'
import get from '@pw-utils/get'

import { Layout, Container, NewsletterForm } from '../components/Layout'
import Section from '../components/Layout/Section'
import Sidebar from '../components/Sidebar'
import PostList from '../components/Posts/List'
import RowOfPosts from '../components/Posts/Row'
import PostsGrid from '../components/Posts/Grid'
import brittneyPhoto from '../images/homepage_brittney.jpg'
import suitcasesPhoto from '../images/suitcases.jpg'

export const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Section display={{ base: 'none', md: 'block' }}>
        <Flex justifyContent="center" pb="8">
          <Heading mx="auto" as="h3" script="oh no">
            404
          </Heading>
        </Flex>

        <Text>Page could not be found</Text>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
